$span-height: 20px;
$span-minimum-padding: $span-height - 6px;
$span-factor: 2;
$span-padding-tb: $span-factor+$span-minimum-padding;
$span-border-top: 1px;
$span-total-height: $span-height+2 * $span-padding-tb;
$span-height-responsive: 51px;
$span-minimum-padding-responsive: 0px;
$span-padding-tb-responsive: $span-factor+$span-minimum-padding-responsive;
$span-total-height-responsive: $span-height-responsive+2 * $span-padding-tb-responsive;
$input-text-top: 11px;
$input-text-top-responsive: $span-padding-tb-responsive - $span-minimum-padding-responsive;
$box-height-1: 1 * $span-height-responsive;
$box-height-2: 2 * $span-height-responsive;
$box-height-3: 3 * $span-height-responsive;
$box-height-4: 4 * $span-height-responsive;
$box-height-5: 5 * $span-height-responsive;
$box-height-6: 6 * $span-height-responsive;
$content-color: #f0f0f0;
$box-text-indent: 1rem;
$focus-indent: 24px;
$focus-bottom-1: $box-height-1+$focus-indent;
$focus-bottom-2: $box-height-2+$focus-indent;
$focus-bottom-3: $box-height-3+$focus-indent;
$focus-bottom-4: $box-height-4+$focus-indent;
.au_databox {
  margin-bottom: 2em;
  position: relative;
  .button-wrapper {
    position: relative;
    >div {
      width: 100%;
      position: absolute;
      bottom: 0;
    }
    @media (max-width: 640px) {
      background-image: none !important;
      height: auto !important;
      position: static;
      overflow: auto;
      >div {
        position: static;
      }
      .db-button {
        float: none;
        width: 100% !important;
        >span {
          border-left: 0 !important;
          height: $span-height-responsive; //padding: $span-padding-tb-responsive 0px;
        }
        >input[type=text] {
          left: 70px;
          top: 11px;
        }
      }
      .buttons-5 {
        &.first-line-full {
          .button-wrapper {
            height: auto;
          }
        }
      }
      .buttons-6 {
        .button-wrapper {
          height: auto;
        }
      }
    }
    .au_focus {
      margin-bottom: 0;
      padding-left: 1rem;
    }
  }
  .content {
    width: 100%;
    background: $content-color;
    padding: $box-text-indent 0;
    overflow: auto;
    >div {
      padding: 0 $box-text-indent;
      position: relative;
      .expandall {
        position: absolute;
        right: 10px;
        top: 0;
        text-decoration: underline;
        cursor: pointer;
      }
    }
    ul {
      margin-top: 0;
      margin-left: 0;
      ul {
        margin-top: .65rem;
      }
      li {
        a {
          text-decoration: none;
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
    ul.letters {
      display: flex;
      justify-content: flex-start;
      flex-flow: row wrap;
      font-size: 1.1rem;
      margin-top: .15rem;
      margin-bottom: .75rem;
      margin-left: 0;
      padding-bottom: .75rem;
      border-bottom: 1px solid #ccc;
      li {
        padding: 0;
        margin: 2px 2px;
        background-color: #aaa;
        text-align: center;
        font-size: 1.2rem;
        &.available {
          button {
            cursor: pointer;
            color: #fff;
          }
        }
        &.active {
          font-weight: bold;
        }
        &:before {
          content: '';
        }
        button {
          font-family: $font-passata-regular;
          color: #ccc;
          text-transform: uppercase;
          width: 20px;
          line-height: inherit;
        }
      }
    }
    .au_collapsible {
      margin-bottom: 0;
      padding: .6rem 0;
      >.csc-header {
        margin-bottom: 1rem;
        &:after {
          font-size: 24px;
        }
        h3 {
          margin: 0;
        }
      }
      &.au_collapsed {
        >.csc-header {
          margin-bottom: 0;
        }
      }
    }
  }
  & .au_focus {
    margin-left: 24px;
    position: absolute;
    margin-bottom: 0;
    &.before {
      position: static;
      padding: 1.5em 0 1.5em 14px;
      margin-left: 0;
    }
  }
  .clear {
    clear: both;
  }
  &.width-full {
    width: 100%;
  }
  .db-button {
    float: left;
    text-indent: $box-text-indent;
    color: #fff;
    position: relative;
    text-align: left;
    padding: 0;
    margin: 0;
    border: 0;
    font: 1.7272727273rem $font-passata-regular;
    &:not(.db-search),
    &.search-filter {
      cursor: pointer;
    }
    >span {
      display: block;
      border-top: $span-border-top solid #fff;
      padding: $span-padding-tb 0px;
      line-height: 1;
      a {
        color: #fff;
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
      }
    }
    $input-left: 65px;
    input[type=text] {
      font-size: 1.25rem;
      position: absolute;
      top: $input-text-top;
      left: $input-left;
      background: #fff url($findicon) no-repeat right center;
      background: rgba(255, 255, 255, .75) url($findicon) no-repeat right center;
      border: 0;
      width: calc(100% - #{$input-left} - #{$box-text-indent});
      padding: 0;
      margin: 0;
      height: 32px;
      text-indent: 10px;
      &.active {
        background: #fff;
        background: rgba(255, 255, 255, .75);
      }
    }
    &.overlay {
      background: rgb(204, 204, 204);
      background: rgba(204, 204, 204, .25);
    }
    &.active {
      background: $content-color;
      color: #000;
      a {
        color: #000;
      }
    }
  }
  &.collapse {
    .db-button {
      &:after {
        color: #fff;
        opacity: 0.8;
        font-family: 'au_icons';
        content: '+';
        float: right;
        font-size: 32px;
        position: absolute;
        top: 10px;
        right: 0;
        margin-right: .3em;
      }
      &.active:after {
        color: #000;
        opacity: 1;
        content: '-'
      }
      &.db-search:after {
        content: '';
      }
    }
  }
  &.stacked {
    .button-wrapper {
      background-image: none !important;
      height: auto !important;
      position: static;
      >div {
        position: static;
      }
      .db-button {
        float: none;
        width: 100% !important;
        >span {
          border-left: 0 !important;
          height: $span-height-responsive; //padding: $span-padding-tb-responsive 0px;
        }
        >input[type=text] {
          left: 70px;
          top: 11px;
        }
      }
      .buttons-5 {
        &.first-line-full {
          .button-wrapper {
            height: auto;
          }
        }
      }
      .buttons-6 {
        .button-wrapper {
          height: auto;
        }
      }
    }
    .content {
      padding-top: 0;
    }
  }
  &.buttons-1 {
    .au_focus {
      bottom: $focus-bottom-1;
    }
    .button-wrapper {
      height: $box-height-1;
    }
    .db-button {
      width: 100%;
      >span {
        text-indent: -9999px;
      }
      >input[type=text] {
        left: 14px;
      }
    }
  }
  &.buttons-2 {
    .button-wrapper {
      height: $box-height-1;
    }
    .au_focus {
      bottom: $focus-bottom-1;
    }
    .db-button {
      width: 50%;
      &:nth-child(2) span {
        border-left: 1px solid #fff;
      }
    }
  }
  &.buttons-3 {
    .button-wrapper {
      height: $box-height-1;
    }
    .au_focus {
      bottom: $focus-bottom-1;
    }
    &.first-line-full {
      .button-wrapper {
        height: $box-height-2;
      }
      .au_focus {
        bottom: $focus-bottom-2;
      }
      .button-1 {
        width: 100%;
      }
      .button-2,
      .button-3 {
        width: 50%;
      }
      .button-2 span {
        border-left: 0;
      }
      .button-3 span {
        border-left: 1px solid #fff;
      }
    }
    .button-1,
    .button-3 {
      width: 33%;
    }
    .button-2 {
      width: 34%;
    }
    .button-2,
    .button-3 {
      span {
        border-left: 1px solid #fff;
      }
    }
  }
  &.buttons-4 {
    .button-wrapper {
      height: $box-height-2;
    }
    .au_focus {
      bottom: $focus-bottom-2;
    }
    &.first-line-full {
      .button-1 {
        width: 100%;
      }
      .button-2 span {
        border-left: 0;
      }
      .button-2,
      .button-4 {
        width: 33%;
      }
      .button-3 {
        width: 34%;
      }
      .button-3,
      .button-4 {
        span {
          border-left: 1px solid #fff;
        }
      }
    }
    .db-button {
      width: 50%;
    }
    .button-2,
    .button-4 {
      span {
        border-left: 1px solid #fff;
      }
    }
  }
  &.buttons-5 {
    .button-wrapper {
      height: $box-height-3;
    }
    .au_focus {
      bottom: $focus-bottom-3;
    }
    &.first-line-full {
      .button-wrapper {
        height: $box-height-2;
      }
      .au_focus {
        bottom: $focus-bottom-2;
      }
      .button-1 {
        width: 100%;
      }
      .button-2,
      .button-3,
      .button-4,
      .button-5 {
        width: 25%;
      }
      .button-3,
      .button-4,
      .button-5 {
        span {
          border-left: 1px solid #fff;
        }
      }
    }
    .button-1 {
      width: 100%;
    }
    .button-2,
    .button-3,
    .button-4,
    .button-5 {
      width: 50%;
    }
    .button-3,
    .button-5 {
      span {
        border-left: 1px solid #fff;
      }
    }
  }
  &.buttons-6 {
    &.first-line-full {
      .button-wrapper {
        height: $box-height-2;
      }
      .au_focus {
        bottom: $focus-bottom-2;
      }
      .button-1 {
        width: 100%;
      }
      .db-button.button-2 {
        span {
          border-left: none;
        }
      }
      .button-2,
      .button-3,
      .button-4,
      .button-5,
      .button-6 {
        width: 20%;
      }
      .button-3,
      .button-4,
      .button-5,
      .button-6 {
        span {
          border-left: 1px solid #fff;
        }
      }
    }
    .button-wrapper {
      height: $box-height-3;
    }
    .au_focus {
      bottom: $focus-bottom-3;
    }
    .db-button {
      width: 50%;
      &:nth-child(even) span {
        border-left: 1px solid #fff;
      }
    }
  }
  &.buttons-7 {
    .button-wrapper {
      height: $box-height-3;
    }
    .au_focus {
      bottom: $focus-bottom-3;
    }
    &.first-line-full {
      .button-wrapper {
        height: $box-height-2;
      }
      .au_focus {
        bottom: $focus-bottom-2;
      }
      .button-1 {
        width: 100%;
      }
      .button-2,
      .button-3,
      .button-4,
      .button-5,
      .button-6,
      .button-7 {
        width: 16.66667%;
      }
      .button-3,
      .button-4,
      .button-5,
      .button-6,
      .button-7 {
        span {
          border-left: 1px solid #fff;
        }
      }
    }
    .button-1 {
      width: 100%;
    }
    .button-2,
    .button-4,
    .button-5,
    .button-7 {
      width: 33%;
    }
    .button-3,
    .button-6 {
      width: 34%;
    }
    .button-3,
    .button-4,
    .button-6,
    .button-7 {
      span {
        border-left: 1px solid #fff;
      }
    }
  }
}

.utility-links .au_databox.stacked {
  margin-bottom: 0;
  .content {
    max-height: 200px;
    overflow: auto;
  }
}