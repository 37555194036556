.au_alphabox {
  overflow: visible;
  margin-bottom: 1rem;
  position: relative;
  >.au_alphabox {
    margin-bottom: 0;
  }
  .dropdown-pane & {
    margin-bottom: 0;
  }
  >div:not(.au_alphabox_content):not(.au_alphabox) { /* because of nested au_alphabox */
    padding: 1rem 1rem 0 1rem;
    overflow: auto;
  }
  p.no-results {
    background-color: #fff;
    color: #555;
    padding: 1rem;
  }
  li.no-results a {
    font-weight: bold;
  }
  ul.letters {
    display: flex;
    justify-content: flex-start;
    flex-flow: row wrap;
    font-size: 1.1rem;
    margin-top: .15rem;
    margin-bottom: .75rem;
    margin-left: 0;
    padding-bottom: .75rem;
    li {
      padding: 0;
      margin: 2px 2px;
      background-color: #aaa;
      text-align: center;
      font-size: 1.2rem;
      &.available {
        button {
          cursor: pointer;
          color: #fff;
        }
      }
      &.active {
        font-weight: bold;
      }
      &:before {
        content: '';
      }
      button {
        font-family: $font-passata-regular;
        color: #ccc;
        text-transform: uppercase;
        width: 20px;
        line-height: inherit;
      }
    }
  }
  ul.items {
    padding: 1rem;
    margin: 0;
    position: relative;
  }
  .student & {
    background: $student-secondary-color;
    .listNav {
      .ln-letters {
        a {
          background-color: #8bad3f;
          &.ln-selected {
            color: #2a4a0f !important;
          }
          &.ln-disabled {
            background-color: #8bad3f;
          }
        }
      }
    }
    .alphabox_list_container {
      border: 2px solid #2a4a0f;
      h3 {
        color: #8bad3f;
        border-bottom: 2px solid #8bad3f;
      }
      ul li:before {
        color: #8bad3f;
      }
    }
    ul.quicklinks {
      a {
        background: #447400;
      }
    }
  }
  h2 {
    color: #fff;
    margin: 0;
    border: 0;
    a {
      color: #fff !important;
      border: none !important;
      &:hover {
        text-decoration: underline !important;
      }
    }
  }
  h3 {
    color: #fff;
    margin: 0 0 1rem 0 !important;
    padding-top: 1rem;
    font-size: 1.25rem;
    border-top: 1px solid #fff;
  }
  input[type=text] {
    background: #afc3ce url($findicon) no-repeat right center;
    border: 0;
    float: none;
    width: 100%;
    margin-bottom: 1.45455rem !important;
    &.active {
      background-color: #afc3ce;
    }
  }
  ::-webkit-input-placeholder {
    color: #333;
  }
  ::-moz-placeholder {
    color: #333;
    opacity: 1;
  }
  :-ms-input-placeholder {
    color: #333;
  }
  .listNav {
    margin: 0 0 calc(.5rem - 2px) 0;
    .ln-letters {
      margin: 1rem 0;
      a {
        background-color: #006ca4;
        border: none !important;
        margin: 0 2px 2px 0;
        color: #fff;
        padding: 2px;
        font-size: 1.25rem;
        &.ln-selected {
          background-color: #fff;
          color: #006ca4 !important;
        }
        &.ln-disabled {
          background-color: #006ca4;
          color: #7c98a8 !important;
        }
      }
      .all {
        display: none;
      }
    }
    font-family: "AUPassataBold";
  }
  .alphabox_list_container {
    background: #fff;
    border: 2px solid #006ca4;
    padding: 0 1rem 0 1rem;
    display: none;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    h3 {
      color: #009ee0;
      border-bottom: 2px solid $staff-color;
    }
    >ul {
      max-height: 200px;
      overflow: initial !important;
      margin-bottom: 2em;
      position: relative;
      li {
        a {
          color: $staff-secondary-color;
          border-bottom: 1px dotted;
        }
      }
    }
  }
  ul.quicklinks {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-left: 0;
    li {
      font: 1.25rem "AUPassataRegular";
      text-transform: uppercase;
      width: 48.9%;
      padding: 0;
      line-height: 1;
      margin-bottom: 6px;
      &:nth-child(odd) {
        margin-right: .5em;
      }
      a {
        display: block;
        background: #006ca4;
        color: #fff;
        border: none !important;
        padding: .5em;
      }
      @media (max-width: 400px) {
        width: 100%;
        margin-right: 0 !important;
        font-size: 1.72727rem;
      }
    }
  }
  .au_alphabox_content {
    margin-top: 1rem;
    margin-bottom: 1rem;
    clear: both;
    >div {
      background: #fff;
      max-height: 300px;
      overflow-y: scroll;
    }
  }
}