.react-autosuggest__container {
  cursor: pointer;

  input[type="text"] {
      margin-bottom: 0;
  }
}

/* delphinus */
.page__content__block {
  .react-autosuggest__container {
    position: relative;
    width: 100%;
  }
  .react-autosuggest__suggestions-list {
    margin-top: 0;
  }
}

.react-autosuggest__suggestions-container {
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
  position: absolute;
  left: 0;
  right: 0;
  background-color: #fff;
  margin: 0;
  z-index: 1;
  color: #333 !important;
  font-size: 1.272727272727rem;
  line-height: 1;
  max-height: 430px;
  overflow: auto;

  li, li:before {
      list-style-type: none !important;
      background-image: none !important;
      margin: 0;
      padding: 0;
      content: '' !important;
      line-height: 1 !important;
      background: none;
  }
}
li.react-autosuggest__suggestion {
  border-bottom: 1px solid #ccc;
  padding: 1.272727272727rem 1rem;
}
.react-autosuggest__suggestion--highlighted {
  background-color: #f0f0f0 !important;
}

.react-autosuggest__suggestions-list {
  margin-bottom: 0;
  margin-left: 0;
}

