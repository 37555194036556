@import "_settings";
$color-selected: #8bad3f;
$color-empty: #f5f5f5;
$color-weekend: #ddd;
$color-border: #ccc;

.au-calendar {
  background-color: #fff;
  font-family: $font-passata-regular;
  width: 224px;

  .au-calendar-days {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 1rem;

    > span, > button {
      width: 32px;
      height: 32px;
      text-align: center;
      text-transform: uppercase;
      line-height: 1;
      font-size: 1rem;
    }

    > span {
      padding-top: 8px;
    }

    > button {
      font-family: $font-passata-regular;
      cursor: pointer;

      &.empty {
        background-color: $color-empty;
      }

      &.weekend:not(.empty) {
        background-color: $color-weekend;
      }

      &.selected {
        border: 2px solid $color-selected;
      }
    }
  }

  .au-calendar-year-month, .au-calendar-time {
    display: flex;
    justify-content: space-between;

    > select {
      width: 108px;
      font-size: 1.090909rem;
      margin-bottom: 0;
    }
  }

  > button {
    font-family: $font-passata-regular;
    text-align: center;
    background-color: $color-selected;
    width: 100%;
    padding: 1rem;
    margin-top: 1rem;
    color: #fff;
    cursor: pointer;
  }
}

.au-datepicker {
  position: relative;

  input[type="text"][readonly] {
    background-color: #fff;
    cursor: auto;

    ~ div {
      position: absolute;
      right: 0;
      z-index: 1000;
      width: 230px;
      border-right: 1px solid $color-border;
      border-left: 1px solid $color-border;
    }
  }

  .au-calendar {
    border-bottom: 1px solid $color-border;
    padding: 2px;
    top: 5.54545rem;
  }

  .au-calendar-close {
    top: 3.54545rem;
    height: 2rem;
    background-color: #fff;

    button {
      position: absolute;
      top: 3px;
      right: 3px;
      font-family: 'au_icons';
      font-size: 1.5rem;
      margin: 0;
      padding: 0;
      background: transparent !important;
      color: #000;
      cursor: pointer;
    }
  }
}