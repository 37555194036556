$padding-default: 1rem;
$white: #fefefe;
$gray-color:#e4e4e4;
$gray-secondary-color:darken($gray-color, 50%);
$mitstudie-sf-color: #cfe1e7;
$mitstudie-obl-color: #f2e4d4;
$mitstudie-val-color: #d1e1d6;
$mitstudie-tlv-color: #e9ccda;
$diagramme-cell-height: 130px;
.au_edutable_eddi_container {
  .print {
    display: inline-block;
    margin-bottom: 1rem;
  }
}

.study-diagramme {
  &.list {
    p {
      padding: .6rem 1rem;
      contain: content;
      a {
        text-decoration: none;
      }
      span:last-child {
        float: right;
      }
    }
  }
  padding: $padding-default;
  margin-bottom: 2rem;
  font-family: "AUPassataRegular";
  background-color: $gray-color;
  table {
    table-layout: fixed;
    width: 100%;
    border-collapse: collapse;
  }
  table,
  thead,
  tbody {
    background-color: transparent !important;
  }
  tr {
    background-color: transparent !important;
  }
  th {
    font-family: "AUPassataBold";
    font-weight: normal;
  }
  th,
  td {
    border: 4px solid $white;
  }
  td {
    padding: 0;
    &:hover {
      background-color: $gray-secondary-color;
      &.sf {
        a {
          color: $mitstudie-sf-color;
        }
      }
      &.obl {
        a {
          color: $mitstudie-obl-color;
        }
      }
      &.val {
        a {
          color: $mitstudie-val-color;
        }
      }
      &.tlv {
        a {
          color: $mitstudie-tlv-color;
        }
      }
      &.ogr {
        a {
          color: $mitstudie-obl-color;
        }
      }
    }
    a {
      padding: 2rem 1rem;
      display: block;
      text-decoration: none;
      border-bottom: none !important;
      font-size: 12px;
      word-break: break-word;
      hyphens: auto;
    }
   @for $i from 1 through 6 {
    &[rowspan="#{$i}"] a {
      min-height: $i * $diagramme-cell-height;
    }
   }
  }
  .sf {
    background-color: $mitstudie-sf-color;
  }
  .obl {
    background-color: $mitstudie-obl-color;
  }
  .val {
    background-color: $mitstudie-val-color;
  }
  .tlv {
    background-color: $mitstudie-tlv-color;
  }
  .ogr {
    background-color: $mitstudie-obl-color;
  }
  &.show-for-small-only {
    @media print {
      display: none;
    }
  }
}