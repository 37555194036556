/* alert */
.au-alert {
  position: fixed;
  z-index: 100;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 300px;
  background-color: #fff;
  padding: 1.3636364rem;

  button {
    margin-top: 1rem;
    margin-bottom: 0;
    padding: .65rem 2rem;

    &.confirm {
      background-color: $alert-confirm-color;
    }

    &.cancel {
      background-color: $alert-cancel-color;
    }

    &:first-of-type {
      margin-right: 1rem;
    }
  }
}

.au-alert-overlay, .au-modal-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, .6);
  z-index: 99;
}
