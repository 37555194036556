@font-face {
  font-family: 'font-awesome';
  src: url('https://fonts.au.dk/fonts/fa-pro-regular-400.otf');
  font-weight: 400;
  font-style: normal;
}

$padding-default: 1.363636364rem;

.layout13,
.layout14,
.layout15,
.layout16,
.layout17 {
  .au-map {
      margin-bottom: $padding-default;

      .react-autosuggest__container {
          margin-bottom: 1rem;
          position: relative;
      }

      .react-autosuggest__suggestions-container {
          max-height: none;
          overflow: visible;

          li:nth-child(n+21) {
              display: none;
          }
      }

      .gm-style-iw {

          .au_collapsible,
          hr {
              display: none;
          }
      }

      &__search {
          display: flex;

          >div {
              flex-basis: 85%;

              @media (max-width: 640px) {
                  flex-basis: 76%;
              }
          }

          button {
              flex-basis: 15%;
              font-family: 'AUPassataRegular';
              border-top: 1px solid #ccc;
              border-right: 1px solid #ccc;
              border-bottom: 1px solid #ccc;
              height: 2.6 * $padding-default;
              cursor: pointer;
              background: none;
              color: black;
              padding: 0;

              &:hover {
                  background: none;
                  color: inherit;
              }

              span::before {
                  font-family: 'font-awesome';
                  content: '\f5a0';
                  display: inline-block;
                  margin-right: 1rem;
              }

              @media (max-width: 640px) {
                  flex-basis: 24%;
              }
          }
      }

      &__overlay-buttons {
          display: flex;
          flex-wrap: wrap;

          button {
              margin-right: 1rem;
          }
      }

      &__person-suggestion {
          >div {
              &:first-child {
                  padding-left: $padding-default;
                  width: 15%;
              }

              &:last-child {
                  padding-left: $padding-default;
                  width: 85%;
              }
          }

          h4,
          p {
              margin: 0;
          }

          img {
              max-width: 100px;
          }
      }

      .vcard,
      .bld-card,
      .overlay-card,
      .top-search {
          >h2 {
              margin-top: 0;
          }

          button {
              cursor: pointer;
              text-decoration: underline;
              font-family: inherit;
              text-align: left;
          }

          strong button {
              font-weight: bold;
          }

          @media (max-width: 640px) {
              padding: $padding-default 0;
          }
      }

      .vcard {
          img {
              float: right;
              margin-left: $padding-default;
              margin-bottom: $padding-default;
              max-width: 120px;
          }

          &::after {
              display: block;
              content: '';
              clear: both;
          }
      }

      &__route {
          margin-bottom: $padding-default;
      }

      .show-on-map {
          display: none;
      }

      .au_collapsible {
          .csc-header+* {
              position: relative;
              max-height: 350px;
              overflow-y: auto;
          }
      }

      &__directions-panel {
          display: flex;
          flex-wrap: wrap;
          padding: 1rem 0 1rem 1rem;
          margin-bottom: 1rem;
          border: 1px solid #cacaca;

          &__modes-of-transportation {
              flex-basis: 100%;
              display: flex;
          }

          &__from-to {
              flex-basis: 85%;
          }

          &__swap {
              flex-basis: 15%;
              display: flex;
              align-items: center;
              justify-content: center;
          }

          .button-route {
              background-color: transparent;
              color: #878787;
              height: 3rem;
              overflow: hidden;
              padding: 0;
              margin-right: 1rem;
              display: inline-flex;
              border: none;

              &--selected {
                  border-bottom: 2px solid black;
              }

              &::before {
                  font-family: 'font-awesome';
                  font-size: 2rem;
                  display: inline-block;
              }

              &--car {
                  width: 22px;

                  &::before {
                      content: '\f1b9';
                  }
              }

              &--walk {
                  width: 13px;

                  &::before {
                      content: '\f554';
                  }
              }

              &--bicycle {
                  width: 27px;

                  &::before {
                      content: '\f206';
                  }
              }

              &--public {
                  width: 22px;
                  padding-top: 4px;

                  &::before {
                      content: '\f207';
                  }
              }

              &--close {
                  margin-left: auto;
                  width: 14px;

                  &::before {
                      content: '\f00d';
                  }
              }

              &--swap {
                  margin: 0 0 1.45455rem 0;
                  width: 24px;

                  &::before {
                      font-size: 3rem;
                      content: '\f883';
                  }
              }
          }
      }

      &__suggestion {
          display: inline-block;

          &::before {
              font-family: 'font-awesome';
              display: inline-block;
              margin-right: .5rem;
          }

          &__building {
              &::before {
                  content: '\f1ad';
              }
          }

          &__department {
              &::before {
                  content: '\f19c';
              }
          }

          &__denmark {
              &::before {
                  content: '\f1ad';
              }
          }

          &__denmark {
              &::before {
                  content: '\f276';
              }
          }

          &__lecture-hall {
              &::before {
                  content: '\f63d';
              }
          }

          &__library {
              &::before {
                  content: '\f02d';
              }
          }

          &__friday-bar {
              &::before {
                  content: '\f0fc';
              }
          }

          &__study-place {
              &::before {
                  content: '\f63d';
              }
          }

          &__canteen {
              &::before {
                  content: '\f2e7';
              }
          }

          &__counselling {
              &::before {
                  content: '\f128';
              }
          }

          &__it-support {
              &::before {
                  content: '\f82d';
              }
          }

          &__myprint {
              &::before {
                  content: '\f02f';
              }
          }
      }

  }

  .au-map.find-container,
  .dropdown-pane .find-container {

      >.row>.column {
          position: relative;
      }

      h3 {
          margin-top: 0;
      }

      .au-map__person-suggestion {
          img {
              max-width: 30px;
          }
      }

      .react-autosuggest__suggestions-container li {
          margin-bottom: 0;
      }

      .vcard,
      .bld-card {
          margin-top: $padding-default;
          padding: 1rem;
          background: #fff;
          color: #333;
          font-size: 1.25rem;
          font-family: Georgia, "Times New Roman", Times, serif;

          hr {
              display: none;
          }

          img {
              width: 70px;
              float: right;
              margin-left: 1rem;
          }

          a {
              color: #000 !important;
              text-decoration: underline;
          }
      }
  }

}