$font-passata-regular: "AUPassataRegular";
$font-passata-bold: "AUPassataBold";
$gray-color:#e4e4e4;
$gray-secondary-color:darken($gray-color, 50%);
$alert-confirm-color: #8bad3f;
$alert-cancel-color: #687989;
$student-color:#8bad3f;
$student-secondary-color:#2a4a0f;
$staff-color: #379fcb;
$staff-secondary-color:#003e5c;
$findicon: 'https://cdn.au.dk/2016/assets/img/databox/find.png';