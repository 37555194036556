.au-pagination {
  display: flex;
  justify-content: space-evenly;

  .current {
    button {
      font-weight: bold;
      text-decoration: underline;
    }
  }
}