/* modal */
.au-modal {
  position: fixed;
  z-index: 100;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 1024px;
  background-color: #fff;
  padding: 1.3636364rem;

  &.medium {
    max-width: 800px;
  }

  &.small {
    max-width: 400px;
  }

  > :nth-child(2) {
    margin-top: 0;
  }

  .close {
    position: absolute;
    right: 1.3636364rem;
    font-family: 'au_icons';
    font-size: 3rem;
    margin: 0;
    padding: 0;
    background: transparent !important;
    color: #000;
  }
}