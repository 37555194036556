.au_flowbox {
  background: #f0efef;
  padding: 2rem;
  margin-bottom: 1rem;
  button {
    text-align: left;
    font-family: 'AUPassataRegular';
    text-decoration: underline;
    cursor: pointer;
  }
  >div>h2 {
    margin: 0 0 1rem 0 !important;
    text-transform: uppercase;
    border: none !important;
    padding: 0 !important;
  }
  .vcard {
    padding: 1rem;
    background-color: rgba(255, 255, 255, 0.95);
    border: 0 none;
    margin: 0 0 1rem 0;
    .fn {
      font-weight: bold;
      font-size: 1.3636363636rem;
    }
    p {
      font-family: inherit;
      font-size: 1.2rem;
      font-weight: normal;
      line-height: 1.6;
      margin-bottom: 1.8181818182rem;
      text-rendering: optimizeLegibility;
    }
  }
  &.partner {
    background: $staff-color;
    >div {
      >h2 {
        color: #fff;
      }
      >p {
        color: #fff;
        a {
          color: #fff !important;
          border-bottom: none !important;
          text-decoration: underline !important;
        }
      }
      >ul li {
        padding-left: 0;
        &::before {
          display: none;
        }
      }
    }
  }
}

.au_flowbox_data {
  display: none;
}

.au_staffcontactbox {
  color: #fff;
  padding: 1.3636364rem;
  background-color: #379fcb;
  margin-bottom: 1em;
  >h2 {
    color: #fff;
    text-transform: uppercase;
    margin: 0 0 1em 0 !important;
  }
}